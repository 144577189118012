<template>
  <footer class="footer">
    <div class="footer__bg">
      <div class="container">
        <div class="footer__content">
          <div class="footer__main">
            <nuxt-link
              :to="localePath('/')"
              class="footer__logo"
            ><img :src="imageLogo"/></nuxt-link>
            <div
              v-if="title"
              v-html="title"
              class="footer__title"
            />
          </div>
          <div class="--divider"/>
          <div class="footer__menus">
            <div class="--main">
              <nuxt-link
                v-for="(_item) in mainMenus"
                :to="localePath(`/${ _item.path }`)"
              >{{ _item.title }}</nuxt-link>
            </div>
            <div class="--divider"/>
            <div class="--secondory">
              <nuxt-link
                v-for="(_item) in secondaryMenus"
                :to="localePath(`/${ _item.path }`)"
              >{{ _item.title }}</nuxt-link>
            </div>
            <div class="--divider"/>
            <div class="--socials">
              <a
                v-for="(_item) in socialsList"
                :href="_item.link"
                target="_blank"
                rel="nofollow"
              >
                <div class="--icon">
                  <img :src="_item.image">
                </div>
                {{ _item.label }}
              </a>
            </div>
          </div>
          <div class="--divider"/>
          <div class="footer__payments">
            <div>
              <div
                v-for="(payment) in paymentsList"
                :style="{'background-color': payment.bgColor}"
              >
                <img :src="payment.image">
              </div>
            </div>
          </div>
        </div>
        <div class="footer__bottom">
          <div v-if="annotation" v-html="annotation"/>
          <div class="--links">
            <nuxt-link :to="localePath('/privacy')">{{$t('footer.personal_data_policy')}}</nuxt-link>
            <nuxt-link :to="localePath('/offer')">{{$t('footer.public_oferta')}}</nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import type { ComputedRef } from 'vue';

const {
  $i18n
} = useNuxtApp();
const localePath = useLocalePath();
const cmsStore = cmsContentStore();
const appConfigStore = useAppConfigStore();

const title: ComputedRef<string> = computed(() => {
  const localeCode = $i18n.locale?.value;
  return appConfigStore?.vars?.[`ФУТЕР_ЗАГОЛОВОК_${ localeCode }`] || appConfigStore?.vars?.[`ФУТЕР_ЗАГОЛОВОК_en`]
});
const annotation: ComputedRef<string> = computed(() => {
  const localeCode = $i18n.locale?.value;
  return appConfigStore?.vars?.[`ФУТЕР_АННОТАЦИЯ_${ localeCode }`] || appConfigStore?.vars?.[`ФУТЕР_АННОТАЦИЯ`] || ""
});
const imageLogo: any = computed(() => {
  return appConfigStore?.$state?.config?.logoFront
});

const mainMenus: ComputedRef<any[]> = computed(() => {
  return [...(cmsStore.$state.menu || [])]
    .filter((t: any) => {
      return Boolean(!t.isHome && !t.isHidden)
    })
});
const secondaryMenus: ComputedRef<any[]> = computed(() => {
  const _list = cmsStore.$state?.menus?.['additional-menu']?.['items'] || [];
  return _list.filter((t: any) => {
    return Boolean(!t.isHome && !t.isHidden)
  })
});
const socialsList: ComputedRef<any[]> = computed(() => {
  const _vars: any = appConfigStore.$state.vars;
  const _config: any = appConfigStore.$state.config;
  const _locale = $i18n.locale?.value;

  let _list: any[] = [];
  if (_config.facebook) {
    _list.push({label: "Facebook", link: _config.facebook, image: useAsset('/assets/img/svg/socials/facebook.svg')})
  }
  if (_config.telegram) {
    _list.push({label: "Telegram", link: _config.telegram, image: useAsset('/assets/img/svg/socials/telegram.svg')})
  }
  if (_config.vkontakte) {
    _list.push({label: "Вконтакте", link: _config.vkontakte, image: useAsset('/assets/img/svg/socials/vk.svg')})
  }

  const _youtubeLink = _vars?.[`СОЦ_СЕТИ_YOUTUBE_${ _locale }`] || _vars?.[`СОЦ_СЕТИ_YOUTUBE`];
  if (_youtubeLink) {
    _list.push({label: "Youtube", link: _youtubeLink, image: useAsset('/assets/img/svg/socials/youtube.svg')})
  }

  return _list
});
const paymentsList: ComputedRef<any[]> = computed(() => {
  return [
    {image: useAsset('/assets/img/svg/payments/payeer.png')},
    {image: useAsset('/assets/img/svg/payments/teather.png')},
    {image: useAsset('/assets/img/svg/payments/qiwi.png')},
    {image: useAsset('/assets/img/svg/payments/visa.png')},
    {image: useAsset('/assets/img/svg/payments/mastercard.png')},
    {image: useAsset('/assets/img/svg/payments/alipay.png')},
    {image: useAsset('/assets/img/svg/payments/bitcoin.png'), bgColor: "#F7931A"},
    {image: useAsset('/assets/img/svg/payments/tron.png'), bgColor: "#020100"},
  ]
});
</script>

<style scoped lang="scss">
.footer {
  padding: 4px;
  box-sizing: border-box;
  margin-top: 64px;
}
.footer__bg {
  background: hsla(var(--secondary-bg)/1);
  border-radius: 32px;
  padding-top: 24px;
  box-sizing: border-box;
}
.footer__content {
  display: flex;

  .--divider {
    width: 1px;
    background: hsla(var(--white)/0.1);
    margin: 0 40px;
  }
}
.footer__main {
  width: 100%;
  max-width: 400px;
}
.footer__menus {
  display: flex;
  flex: auto;

  .--main {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  .--main a {
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    color: hsla(var(--white)/1);

    &:hover {
      text-decoration: underline;
    }
  }

  .--secondory {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  .--secondory a {
    font-size: 14px;
    line-height: 1;
    color: hsla(var(--white)/0.8);
    letter-spacing: -0.02em;
    &:hover {
      text-decoration: underline;
    }
  }

  .--socials {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .--socials a {
    display: flex;
    align-items: center;
    gap: 12px;

    font-size: 14px;
    line-height: 1;
    color: hsla(var(--white)/0.7);

    div {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background: hsla(var(--link-tx)/1);
    }
  }
}
.footer__payments {
  width: calc(54px * 3 + 20px);

  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    & > * {
      width: 54px;
      height: 54px;
      border-radius: 100%;
      position: relative;
      z-index: 0;
      overflow: hidden;
      background: hsla(var(--footer-payment-round)/1);

      img {
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 2px); height: calc(100% + 2px);
        max-width: initial; max-height: initial;
        object-fit: contain;
      }
    }
  }
}
.footer__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid hsla(var(--white)/0.1);

  a:hover {
    text-decoration: underline;
  }
  div, a {
    font-size: 13px;
    line-height: 1.3;
    color: hsla(var(--white)/0.5);
  }
  .--links {
    display: flex;
    gap: 35px;
  }
}

.footer__logo {
  display: flex;
  max-width: 100%;
  max-height: 56px;
  margin-bottom: 17px;

  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
}
.footer__title {
  font-size: 40px;
  line-height: 1.1;
  font-weight: 700;
  color: hsla(var(--white)/1);
}

@media (max-width: 1399px) {
  .footer__content {
    .--divider {
      margin: 0 24px;
    }
  }
  .footer__main {
    max-width: 320px;
  }

  .footer__title {
    font-size: 32px;
  }
}
@media (max-width: 1199px) {
  .footer__content {
    .--divider {
      margin: 0 16px;
    }
  }
  .footer__main {
    max-width: 260px;
  }
  .footer__payments {
    & > div > * {
      width: 40px;
      height: 40px;
    }
  }

  .footer__title {
    font-size: 25px;
  }
}
@media (max-width: 1023px) {
  .footer__bg {
    padding-top: 20px;
    border-radius: 24px;
  }
  .footer__content {
    flex-direction: column;
    .--divider {
      width: 100%;
      height: 1px;
      margin: 16px 0;
    }
  }
  .footer__main {
    max-width: initial;
  }
  .footer__menus {
    flex-direction: column;

    .--socials {
      flex-direction: row;
      flex-wrap: wrap;
      & > * {
        width: calc(100% / 3 - 10px);
      }
    }
  }
  .footer__payments {
    width: 100%;
    & > div {
      gap: 20px;
    }
  }
  .footer__bottom {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .--links {
      flex-direction: column;
      gap: 16px;
    }
  }
}
@media (max-width: 639px) {
  .footer__menus .--socials > * {
    width: calc(50% - 10px);
  }
}
</style>
